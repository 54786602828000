import { useEffect, useState } from "react";

import {
  useGeneratePPTXMutation,
  useLazyGetPPTXQuery,
} from "@store/api/export";
import { downloadFileFromUrl } from "@utils/fonctions.utils";

import { type AssetTypology } from "types/api/export";

interface UseExportPPTXReturn {
  exportPPTX: (
    seasonCleanName: string,
    typology: AssetTypology,
  ) => Promise<void>;
  isLoading: boolean;
  loadingTypology: AssetTypology | null;
  error: Error | null;
}

const useExportPPTX = (pullingInterval = 5): UseExportPPTXReturn => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTypology, setLoadingTypology] = useState<AssetTypology | null>(
    null,
  );
  const [error, setError] = useState<Error | null>(null);

  const [getPPTX] = useLazyGetPPTXQuery();
  const [generatePPTX] = useGeneratePPTXMutation();

  const exportPPTX = async (
    seasonCleanName: string,
    typology: AssetTypology,
  ) => {
    setIsLoading(true);
    setLoadingTypology(typology);
    setError(null);

    try {
      const generatePPTXResponse = await generatePPTX({
        seasonCleanName,
        typology,
      }).unwrap();

      const { transactionId } = generatePPTXResponse;
      if (transactionId) {
        const interval = setInterval(async () => {
          try {
            const getPPTXResponse = await getPPTX({
              seasonCleanName,
              typology,
              transactionId,
            }).unwrap();

            if (getPPTXResponse.url) {
              // if file is available, stop pulling and download the file through the signed url
              clearInterval(interval);
              setIntervalId(null);
              setIsLoading(false);
              setLoadingTypology(null);
              downloadFileFromUrl(getPPTXResponse.url);
            }
            // If file is not available yet, keep pulling
          } catch (err) {
            clearInterval(interval);
            setError(err as Error);
            setIsLoading(false);
            setLoadingTypology(null);
          }
          // convert in ms
        }, pullingInterval * 1000);
        setIntervalId(interval);
      }
    } catch (err) {
      setError(err as Error);
      setIsLoading(false);
      setLoadingTypology(null);
    }
  };

  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [intervalId]);

  return { exportPPTX, isLoading, loadingTypology, error };
};

export default useExportPPTX;
