import { METHODS } from "@src/types";
import { defaultApi } from "@store/api/api";
import endpoints from "@store/api/endpoints";

import {
  type generatePPTXRequest,
  type generatePPTXResponse,
  type getPPTXRequest,
  type getPPTXResponse,
} from "types/api/export";

export const exportApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    generatePPTX: build.mutation<generatePPTXResponse, generatePPTXRequest>({
      query: ({ seasonCleanName, typology }) => {
        return {
          url: endpoints.export.withSeasonCleanName(seasonCleanName),
          params: { typology },
          method: METHODS.POST,
        };
      },
    }),
    getPPTX: build.query<getPPTXResponse, getPPTXRequest>({
      query: ({ seasonCleanName, typology, transactionId }) => {
        return {
          url: endpoints.export.withSeasonCleanName(seasonCleanName),
          params: { typology, transactionId },
          cache: "no-cache",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetPPTXQuery, useGeneratePPTXMutation } = exportApi;
