export enum AssetTypology {
  COMPOS = "COMPOS",
  SMEAR = "SMEAR",
  VIDEO = "VIDEO",
  INCARNE_DIGIT = "INCARNE_DIGIT",
  KV_CAMPAIGN_PRINT = "KV_CAMPAIGN_PRINT",
  FULL = "FULL",
}

export type generatePPTXRequest = {
  seasonCleanName: string;
  typology: AssetTypology;
};

export type generatePPTXResponse = {
  transactionId: string;
};

export type getPPTXRequest = generatePPTXRequest & generatePPTXResponse;

export type getPPTXResponse = {
  url: string;
};
